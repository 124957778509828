import { ROOT_ELEMENT_ID } from '@nike/ciclp-config';
import { isInViewport, performImageLoading } from './helpers';

export const handleDocumentLoad = () => {
  Array.from(
    document.querySelectorAll(
      `#${ROOT_ELEMENT_ID} [data-container-type="image"] [data-qa="image-wrapper"]`,
    ),
  )
    .filter(isInViewport)
    .forEach(performImageLoading);
};

document.addEventListener('DOMContentLoaded', handleDocumentLoad);
