export const BRAND_NAMES = {
  JORDAN: 'jordan',
  NIKE: 'nike',
  NIKE_JOURNAL: 'nikeJournal',
};

export const PAGE_TYPES = {
  ARTICLE: 'article',
  PAGE: 'page',
  SIZE_CHART: 'size_chart',
};

/**
 * Map of the supported flags
 */
export const FEATURE_FLAGS = {
  DISABLE_ANALYTICS_CLIENT: 'x-disable-analytics-client',
  DISABLE_APPS_FLYER: 'x-disable-appsflyer',
  DISABLE_GLOBAL_NAV: 'x-disable-global-nav',
  DISABLE_MC: 'x-disable-mc',
  DISABLE_SHOP_CART: 'x-disable-shop-cart',
  DISABLE_UI_EXPERIMENTS: 'x-disable-ui-experiments',
  DISABLE_UNITE: 'x-disable-unite',
  ENABLE_CONSOLE_LOGGING: 'x-enable-console-logging',
  WITH_MOCKS: 'clp-with-mocks',
};
/**
 * Map of the supported utility flags
 */
export const UTILITY_FLAGS = {
  FORCED_VARIATIONS: 'forcedVariations', // to be used by optimizely
  NIKE_ENV: 'nikeEnv',
  PREVIEW_VARIANT_ID: 'variationId',
  TS: 'ts',
};

export const NIKE_BASE_URL = `https://${process.env.NEXT_PUBLIC_HOST_NAME}`;
export const API_BASE_URL = `https://${process.env.NEXT_PUBLIC_API_HOST_NAME}`;

export const APPS_SMART_BANNER_ID = '_td_smartbanner_js'; // smart-banner ID should be a fixed value, never change it

export const CHANNEL_IDS = {
  BANNER: '8fac54d0-ed4f-4cf5-b829-e04d3d6008f8',
  DEFAULT: 'd9a5bc42-4b9c-4976-858a-f159cf99c647',
  SNKRS: '010794e5-35fe-4e32-aaff-cd2c74f89d61',
};

export const COLLECTIONS = {
  howTo: {
    collectionId: 'fe94947a-f05b-4a2b-a438-e4ddf24477eb',
  },
  nikeJournal: {
    collectionId: 'a169eb4b-d643-419b-aa03-988d18535d25',
    taxonomyTag: '36d163a1-fa45-45c0-bfbb-3d174015d851',
  },
  sizeCharts: {
    collectionId: '32c0681c-e627-4f6d-9390-ce83e7fba02c',
  },
};

export const segmentProdKey = '2iv4Qa7rFEipAs4iE850BkBpTYQvlAFZ';
export const segmentTestKey = 'eh46CSMEgCao5Y3uW7Jh1bBTAA34vEfU';

// (\/[a-z]{2})? - part for checking of location and lang
export const PREVIEW_REGEXP = /^(\/[a-z]{2})?(\/[a-z]{2})?(\/clp)?\/thread/i;

export const clientIds = {
  homepage: 'nike:dotcom.browse.homepage:client:1.0',
  landingpage: 'nike:dotcom.browse.landingpage:client:1.0',
  product_recommender: 'com.nike.commerce.nikedotcom.web',
};

export const BRANDS = {
  [BRAND_NAMES.JORDAN]: {
    collectionId: '4b69f63c5e87c687f251bdea1f82121b32018ae5',
  },
};

// id of node that mounts react app
export const ROOT_ELEMENT_ID = 'ciclp-app';
export const NIKE_SHOP_ELEMENT_ID = 'nike-shop-root';
export const ADOBE_TARGET_COOKIE_NAME = 'at_lp_exp';
export const DEFAULT_OPTIMIZATION_LOCATION_NAME = 'landingpage_layout';
export const ABOVE_THE_FOLD_OPTIMIZATION_LOCATION_NAME =
  'landingpage_abovethefoldLayout';

// Measurement-related constants extracted here from Redux app to enable Bra Size Finder bundle splitting
export const MEASUREMENT_TYPE = 'IMPERIAL/METRIC';
export const MEASUREMENTS_UNITS = {
  IMPERIAL: 'IMPERIAL',
  METRIC: 'METRIC',
};

export const DEFAULT_IMPERIAL_MARKETPLACES = ['US', 'GB', 'CA'];
