export const DESKTOP = 'desktop';
export const DESKTOP_LARGE = 'desktop-large';
export const DESKTOP_WIDE = 'desktop-wide';
export const TABLET = 'tablet';
export const MOBILE = 'mobile';
export const DIMENSION_TYPES = {
  DESKTOP,
  DESKTOP_LARGE,
  DESKTOP_WIDE,
  MOBILE,
  TABLET,
};
export const MEDIA_DESKTOP_WIDE = 1920;
export const MEDIA_DESKTOP_LARGE = 1440;
export const MEDIA_DESKTOP = 960;
export const MEDIA_TABLET = 600;

export const getElementBySelector = selector =>
  document.querySelector(selector);

export const getHeader = () => getElementBySelector('.pre-l-header-container');

export const getDimensionType = () => {
  if (!__IS_WEB__) return MOBILE;
  if (window.matchMedia(`(min-width: ${MEDIA_DESKTOP_WIDE}px)`).matches)
    return DESKTOP_WIDE;
  if (window.matchMedia(`(min-width: ${MEDIA_DESKTOP_LARGE}px)`).matches)
    return DESKTOP_LARGE;
  if (window.matchMedia(`(min-width: ${MEDIA_DESKTOP}px)`).matches)
    return DESKTOP;
  if (
    window.matchMedia(
      `(min-width: ${MEDIA_TABLET}px) and (max-width: ${MEDIA_DESKTOP - 1}px)`,
    ).matches
  )
    return TABLET;
  if (window.matchMedia(`(max-width: ${MEDIA_TABLET - 1}px)`).matches)
    return MOBILE;
  return TABLET;
};

export const isPortraitView = () => {
  if (global.window && global.window.matchMedia) {
    return global.window.matchMedia(
      `(max-width: ${MEDIA_DESKTOP}px) and (orientation:portrait)`,
    ).matches;
  }

  return true;
};
