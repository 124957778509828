// importing directly from helpers for better tree shaking
import {
  getUrl,
  getMediaFromRef,
} from '@nike/ciclp-react-components/components/cloudinaryImage/helpers';
import { isPortraitView } from '@nike/ciclp-react-components/context/utils';

export const performImageLoading = element => {
  const img = getMediaFromRef(element);
  if (!img) {
    return;
  }
  img.dataset.lcpLoaded = true;
  img.src = getUrl(
    img.dataset?.portraitUrl,
    img.dataset?.landscapeUrl,
    element.clientWidth,
    element.clientHeight,
    isPortraitView(),
  );
  img.onload = () => {
    img.classList.add(img.dataset.imageLoadedClass);
  };
};

export const isInViewport = element => {
  const rect = element.getBoundingClientRect();

  return (
    rect.width > 0 &&
    rect.height > 0 &&
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
